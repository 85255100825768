/* Carousel.module.css */

.carousel {
  display: flex;
  overflow-x: auto;
  gap: 1rem;
  padding: 1rem;
  scroll-snap-type: x mandatory;
}

.carousel-item {
  flex: 0 0 220px;
  scroll-snap-align: start;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.carousel-item:hover {
  transform: scale(1.05);
}

.carousel-item img {
  width: 100%;
  border-radius: 10px;
}
