/* Footer.module.css */

.footer {
    display: flex;
    justify-content: space-around;
    padding: 15px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    margin-top: 40px;
}

.ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.li {
    margin: 0 10px;
}

.a {
    color: #333;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s;
}

.a:hover {
    color: #ff7e5f;
}
