/* Tools.module.css */
.tools {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .tool {
    margin-bottom: 30px;
  }
  
  .tool h3 {
    color: #0056b3;
    margin-bottom: 10px;
  }
  
  textarea,
  input[type="text"],
  input[type="number"],
  input[type="checkbox"] {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
  }
  
  textarea {
    height: 120px;
    resize: vertical;
  }
  
  .toolButton {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .toolButton:hover {
    background-color: #0056b3;
  }
  
  .result {
    background-color: #e9ecef;
    padding: 10px;
    border-radius: 4px;
    margin-top: 10px;
    white-space: pre-wrap;
  }
  
  .checkboxLabel {
    display: block;
    margin-top: 10px;
    font-size: 16px;
  }
  
  .urlInput {
    width: calc(100% - 100px);
    display: inline-block;
  }
  
  .curlInput {
    width: 100%;
  }
  
  .jsonInput,
  .jsonPathInput {
    width: calc(100% - 100px);
    display: inline-block;
  }
  