/* Carousel.module.css */

.carousel {
    display: flex;
    justify-content: space-around;
    overflow-x: auto;
    padding: 20px;
    margin: 20px 0;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.05); /* Softer shadow */
    transition: all 0.3s ease-in-out;
}

.carousel:hover {
    transform: scale(1.01); /* Slightly reduce the scale on hover */
}

.carouselItem {
    min-width: 250px;
    margin-right: 20px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
}

.carouselItem:hover {
    transform: scale(1.05);
}

.image {
    width: 100%;
    height: auto;
    border-radius: 20px;
}

.carouselItem h3 {
    text-align: center;
    margin-top: 10px;
    color: #333;
    font-size: 1.4rem;
}
