/* ContentSection.module.css */

.contentSection {
    background-color: #ffffff;
    padding: 20px;
    margin: 20px 0;
    border-radius: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Lighter shadow */
    text-align: center;
}

.contentSection h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
}

.contentSection p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #555;
}

.image {
    max-width: 100%;
    height: auto;
    border-radius: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05); /* Softer shadow */
}
